
import { defineComponent, computed } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { useCheckout } from '@/composables/checkout/useCheckout'
import { cpf, cnpj } from 'cpf-cnpj-validator'

import { email as emailRule, required, minLength, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    BaseInput,
  },

  setup () {

    const { t, locale } = useI18n()

    const {
      checkoutFormData,
      isInternational,
      updateCheckoutFormData
    } = useCheckout()

    const name = computed({
      get: () => checkoutFormData.value.name,
      set: (value) => updateCheckoutFormData({name: value})
    })

    const email = computed({
      get: () => checkoutFormData.value.email,
      set: (value) => updateCheckoutFormData({email: value})
    })

    const phone = computed({
      get: () => checkoutFormData.value.phone,
      set: (value) => updateCheckoutFormData({phone: value})
    })

    const document = computed({
      get: () => checkoutFormData.value.document,
      set: (value) => updateCheckoutFormData({document: value})
    })

    const isDocumentRequired = computed(() => {
      return !isInternational.value
    })

    const documentRequiredValidator = (value: string) => {
      return isDocumentRequired.value ? !!value : true
    }

    const documentValidator = (value: any) => {
      if (!isDocumentRequired.value && value.trim().length === 0) {
        return true
      }

      const documentType = value.replace(/[^0-9]/g,'').length <= 11 ? 'CPF' : 'CNPJ'

      if (documentType === 'CPF') {
        return cpf.isValid(value)
      }
      return cnpj.isValid(value)
    }

    const rules = computed(() => {
      const phoneLength = locale.value === 'pt-br'
        ? 14
        : 1

      return {
        name: {
          required: helpers.withMessage(t('validations.required'), required)
        },
        email: {
          required: helpers.withMessage(t('validations.required'), required),
          email: helpers.withMessage(t('validations.email'), emailRule)
        },
        document: {
          documentRequire: helpers.withMessage('obrigatório', documentRequiredValidator),
          document: helpers.withMessage(t('validations.cpf_cnpj'), documentValidator)
        },
        phone: {
          required: helpers.withMessage(t('validations.required'), required),
          minLength: helpers.withMessage(
            t('validations.phone'),
            minLength(phoneLength)
          )
        }
      }
    })

    const v$ = useVuelidate(rules, {
      name,
      email,
      phone,
      document,
    })

    const emailErrorMessage = computed(() => v$.value.email.$errors[0]?.$message as string || undefined)
    const nameErrorMessage = computed(() => v$.value.name.$errors[0]?.$message as string || undefined)
    const documentErrorMessage = computed(() => v$.value.document.$errors[0]?.$message as string || undefined)
    const phoneErrorMessage = computed(() => v$.value.phone.$errors[0]?.$message as string || undefined)

    return {
      name,
      email,
      phone,
      document,
      isDocumentRequired,
      emailErrorMessage,
      nameErrorMessage,
      documentErrorMessage,
      phoneErrorMessage,
      isInternational
    }
  }
})
